/* External dependencies */
import React from 'react';

/* Internal dependencies */
import Track from '../track/Track';
import Artist from '../artist/Artist';
import User from '../user/User';
import Event from '../event/Event';
import Community from '../community/Community';
import Collection from 'src/collection/CollectionMediaObject';
import Venue from 'src/venue/Venue';

type OwnProps = {
  item: any | any;
  right?: React.ReactNode;
};

type Props = OwnProps & any;

const Item: React.FunctionComponent<Props> = ({ item, right, ...props }) => {
  const { type } = item;
  
  if (type === 'track') return <Track track={item} right={right} {...props} />;
  if (type === 'artist') return <Artist artist={item} right={right} {...props} />;
  if (type === 'user') return <User user={item} right={right} {...props} />;
  if (type === 'event') return <Event event={item} right={right} {...props} />;
  if (type === 'community') return <Community community={item} right={right} {...props} />;
  if (type === 'collection') return <Collection collection={item} right={right} {...props} />;
  if (type === 'venue') return <Venue venue={item} right={right} {...props} />;

  return null;
};

export default Item;

/* Internal dependencies */
import API from '.';

export const addFollowForItem = async (itemId: string) =>
  await API.post({
    path: `/follows`,
    options: {
      body: { itemId },
    },
  });

export const removeFollowForItem = async (itemId: string) =>
  await API.del({
    path: `/follows/follow:${itemId}`,
  });
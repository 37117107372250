/* External dependencies */
import React, { FormEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { signUp, signIn, autoSignIn, confirmSignUp, confirmSignIn, fetchUserAttributes } from 'aws-amplify/auth';
import PhoneInput from 'react-phone-number-input';
import VerificationInput from 'react-verification-input';
import get from 'lodash/get';
import 'react-phone-number-input/style.css';

/* Internal dependencies */
import { ReactComponent as Logo } from '../assets/images/beatmatch-horizontal.svg';
import ConnectToFacebookButton from '../connectToFacebookButton/ConnectToFacebookButton';
import Colors from 'src/colors';
// import ConnectToAppleButton from 'src/connectToAppleButton/ConnectToAppleButton';
import Button from 'src/button/Button';
import Icon, { Icons } from 'src/icon/Icon';
import './AuthModal.scss';
import { Notification } from 'src/types/Notification';
import { addNotification } from 'src/store/ducks/notifications';
import Spinner from 'src/spinner/Spinner';
import { createUser, getUser, updateLastNameForUser, updateNameForUser } from 'src/api/users';
import { modelUser } from 'src/store/helpers/users';
import { cleanObject } from 'src/common/cleanObject';
import { User } from 'src/types/User';
import { CurrentUserState, getCurrentUser, updateCurrentUser } from 'src/store/ducks/currentUser';
import Stepper, { Step } from 'src/stepper/Stepper';
import { ApplicationState } from 'src/store';
import { getIdentityId } from 'src/auth';
import Analytics, { AuthenticationMethod } from '../analytics/Analytics';

enum PhoneStep {
  phoneNumber = 'phoneNumber',
  verificationCode = 'verificationCode',
  profile = 'profile',
}

enum Mode {
  signIn = 'signIn',
  signUp = 'signUp',
}

type OwnProps = {
  header?: string;
  subheader?: string;
  show?: boolean;
  onSuccess?: (user?: User) => void;
  onShow(): void;
  onClose?: () => void;
  method?: 'facebook' | 'phone';
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type DispatchProps = {
  updateCurrentUser(user: User): void;
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  phoneStep?: PhoneStep;
  phoneNumber?: string;
  mode: Mode;
  phoneNumberLoading: boolean;
  loading: boolean;
  authUser: any;
  verificationCode: string;
  currentUserName: string;
  currentUserLastName: string;
  nameErrorMessage?: string;
};

const VERIFICATION_CODE_LENGTH = 6;
const phoneStepToIndex = {
  [PhoneStep.phoneNumber]: 0,
  [PhoneStep.verificationCode]: 1,
  [PhoneStep.profile]: 2,
};

class AuthModal extends React.Component<Props, State> {
  fakePassword: string | undefined;
  hasAttemptedVerifyCode: boolean;

  constructor(props: Props) {
    super(props);
    this.hasAttemptedVerifyCode = false;

    const { method = 'phone' } = props;

    this.state = {
      phoneStep: Boolean(method === 'phone') ? PhoneStep.phoneNumber : undefined,
      phoneNumber: '',
      mode: Mode.signUp,
      phoneNumberLoading: false,
      loading: false,
      authUser: undefined,
      verificationCode: '',
      currentUserName: '',
      currentUserLastName: '',
      nameErrorMessage: undefined,
    };
  }

  shouldComponentUpdate(_: Props, nextState: State) {
    if (nextState.verificationCode.length === VERIFICATION_CODE_LENGTH && !this.hasAttemptedVerifyCode) {
      this.verifyCode(nextState);
    }
    return true;
  }

  handlePhoneNumberUpdate = (phoneNumber: string | undefined) => {
    this.setState({ phoneNumber });
  };

  handleVerificationCodeUpdate = (verificationCode: string) => {
    this.setState({ verificationCode });
  };

  handlePhoneNumberSignIn = async () => {
    const { mode, phoneNumber, phoneStep } = this.state;
    const { addNotification } = this.props;
    let authUser;

    console.log('mode', mode);
    console.log('phoneNumber', phoneNumber);
    console.log('phoneStep', phoneStep);

    if (phoneStep === PhoneStep.profile) {
      return await this.updateName();
    }

    try {
      this.setState({ phoneNumberLoading: true });
      if (!phoneNumber) {
        addNotification({
          title: 'Missing phone number',
          message: 'You must enter a phone number',
          variant: 'danger',
        });
        return;
      }

      const username = phoneNumber;

      if (mode === Mode.signUp) {
        this.fakePassword = `${Math.floor(100000 + Math.random() * 900000)}`;
        authUser = await signUp({
          username,
          password: this.fakePassword,
          options: {
            userAttributes: { phone_number: phoneNumber },
            autoSignIn: true,
          },
        });
      } else {
        authUser = await signIn({
          username: username!,
          options: { authFlowType: 'CUSTOM_WITHOUT_SRP' },
        });
      }

      this.setState({ authUser });

      console.log('authUser', authUser);
      console.log('authUse.nextStep', get(authUser, 'nextStep'));
      console.log('authUse.nextStep.signInStep', get(authUser, 'nextStep.signInStep'));
      const signInStep = get(authUser, 'nextStep.signInStep');
      const signUpStep = get(authUser, 'nextStep.signUpStep');

      if (signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE' || get(authUser, 'codeDeliveryDetails')) {
        this.setState({ phoneStep: PhoneStep.verificationCode });
      } else if (signUpStep === 'COMPLETE_AUTO_SIGN_IN') {
        const signInOutput = await autoSignIn();
        console.log('autoSignIn signInOutput', signInOutput);
      }
    } catch (e: any) {
      console.log('sign in error', e);
      console.log('sign in error code', e.code);
      console.log('sign in error name', e.name);
      console.log('sign in error messages', e.message);

      if (e.name === 'UsernameExistsException') {
        console.log('should call handlePhoneNumberSignIn again...');
        this.setState({ mode: Mode.signIn }, this.handlePhoneNumberSignIn);
      } else if (e.name === 'UserNotFoundException') {
        this.setState({ mode: Mode.signUp }, this.handlePhoneNumberSignIn);
      } else if (e.name === 'UserNotConfirmedException') {
        this.setState({ mode: Mode.signUp, phoneStep: PhoneStep.verificationCode });
      } else if (e.name === 'UserAlreadyAuthenticatedException') {
        this.postPhoneNumberSignIn();
      }
    } finally {
      this.setState({ phoneNumberLoading: false });
    }
  };

  verifyCode = async (state: State) => {
    const { mode, authUser, verificationCode, phoneNumber } = state;
    const { addNotification } = this.props;

    try {
      this.setState({ loading: true });

      console.log('this.hasAttemptedVerifyCode', this.hasAttemptedVerifyCode);

      if (!this.hasAttemptedVerifyCode) {
        this.hasAttemptedVerifyCode = true;
        const username = phoneNumber;
        let signInResponse;

        if (mode === Mode.signUp) {
          await confirmSignUp({
            username: username!,
            confirmationCode: verificationCode,
            // { forceAliasCreation: true }
          });
          signInResponse = await signIn({ username: username!, password: this.fakePassword });
          console.log('signInResponse Mode.signUp', signInResponse);
        } else {
          // await confirmSignUp({
          //   username: username!,
          //   confirmationCode: verificationCode,
          //   // { forceAliasCreation: true }
          // });
          signInResponse = await confirmSignIn({
            challengeResponse: verificationCode,
          });
          // console.log('signInResponse authUser', signInResponse);
        }

        console.log('signInResponse', signInResponse);

        await this.postPhoneNumberSignIn();
      }
    } catch (e) {
      this.hasAttemptedVerifyCode = false;
      console.log('verify code error', e);
      addNotification({
        title: 'Invalid code',
        message: 'The code you entered was incorrect.',
        variant: 'danger',
      });
      this.setState({ loading: false, verificationCode: '', phoneStep: PhoneStep.phoneNumber });
    } finally {
      this.setState({ loading: false });
    }
  };

  postPhoneNumberSignIn = async () => {
    const { updateCurrentUser, onSuccess } = this.props;
    const [attributes, identityId] = await Promise.all([
      fetchUserAttributes(),
      getIdentityId(),
    ]);
    console.log('postPhoneNumberSignIn attributes', attributes);
    const userId = `user:${identityId}`;
    let user: User | undefined;

    try {
      user = await getUser(userId, { mini: true });

      if (!user || !user.id) {
        throw new Error('No existing user or user does not have an ID.');
      }

      await Analytics.trackLogin({ method: AuthenticationMethod.phoneNumber, userId, email: user.email, phone: user.phone });
    } catch (e) {
      console.log('getUser error', e);
      user = await createUser(cleanObject({
        phone: attributes.phone_number,
        email: attributes.email,
        // referralId: get(link, 'type') === LinkType.user ? get(link, 'id') : undefined,
      }));

      await Analytics.trackSignUp({ method: AuthenticationMethod.phoneNumber, userId, email: user.email, phone: user.phone });
    }

    const modeledUser = modelUser(user);
    console.log('post sign in updating current user...', modeledUser);

    updateCurrentUser(modeledUser);

    console.log('postPhoneNumberSignIn user.name', user.name);

    if (Boolean(user) && (!Boolean(user.name) || !Boolean(user.lastName))) {
      this.setState({
        phoneStep: PhoneStep.profile,
        currentUserName: get(user, 'name', ''),
        currentUserLastName: get(user, 'lastName', ''),
      });
    } else {
      onSuccess && onSuccess(modeledUser);
    }
  };

  updateName = async () => {
    const { currentUser, updateCurrentUser, onSuccess } = this.props;
    const { currentUserName, currentUserLastName } = this.state;
    let modeledUser = currentUser;
    console.log('updateName currentUser', currentUser);

    if (!currentUser || !currentUser.id) return;

    if (!currentUserName || !currentUserName.trim()) {
      this.setState({
        nameErrorMessage: 'Must enter a valid first name.',
      });
      return;
    }

    if (!currentUserLastName || !currentUserLastName.trim()) {
      this.setState({
        nameErrorMessage: 'Must enter a valid last name.',
      });
      return;
    }

    try {
      this.setState({ phoneNumberLoading: true, nameErrorMessage: undefined });
      const [updatedUser] = await Promise.all([
        updateNameForUser(currentUser.id, currentUserName),
        updateLastNameForUser(currentUser.id, currentUserLastName),
      ]);
      modeledUser = modelUser(updatedUser);
      updateCurrentUser(modeledUser);
    } catch (e) {
      console.log('updateName error: ', e);
    }

    onSuccess && onSuccess(modeledUser!);
    this.setState({ phoneNumberLoading: false });
  };

  handleFirstNameUpdate = (e: FormEvent) => {
    const currentUserName: string = get(e, 'target.value', '');

    this.setState({ currentUserName });
  };

  handleLastNameUpdate = (e: FormEvent) => {
    const currentUserLastName: string = get(e, 'target.value', '');

    this.setState({ currentUserLastName });
  };

  showPhoneNumberStep = () => { this.setState({ phoneStep: PhoneStep.phoneNumber }); };

  hidePhoneNumberStep = () => { this.setState({ phoneStep: undefined }); };

  showVerificationCode = () => { this.setState({ phoneStep: PhoneStep.verificationCode }); };

  onClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose && onClose();

      this.hidePhoneNumberStep();
    }
  };

  render() {
    const {
      show = false, onSuccess,
      onShow, onClose,
      header = 'Sign in to continue',
      subheader,
    } = this.props;
    const {
      phoneStep, phoneNumber, phoneNumberLoading,
      loading, verificationCode, currentUserName = '',
      currentUserLastName = '', nameErrorMessage,
    } = this.state;

    if (loading) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ position: 'absolute', top: 0, left: 0, minHeight: '100vh', minWidth: '100vw', height: '100%', backgroundColor: 'rgba(0,0,0,0.75)' }}
        >
          <Spinner variant="light" />
        </div>
      );
    }

    const phoneSteps: Step[] = [
      {
        title: 'Phone number',
        content: (
          <PhoneInput
            placeholder="Enter phone number"
            defaultCountry="US"
            className="mb-4"
            value={phoneNumber}
            onChange={this.handlePhoneNumberUpdate}
          />
        ),
      },
      {
        title: 'Verification code',
        content: (
          <div className="pt-3 pb-3 d-flex flex-column align-items-center mb-2" style={{ width: '100%' }}>
            <div className="d-flex flex-column align-items-center">
              <p className="text-secondary text-center mb-3">
                Enter the verification code sent to {phoneNumber}
              </p>
            </div>
            <VerificationInput
              value={verificationCode}
              length={VERIFICATION_CODE_LENGTH}
              placeholder=""
              autoFocus={true}
              passwordMode={false}
              onChange={this.handleVerificationCodeUpdate}
            />
          </div>
        ),
      },
      {
        title: 'Profile',
        content: (
          <div className="pt-3 pb-3 d-flex flex-column align-items-center mb-2" style={{ width: '100%' }}>
            <Form.Control
              type="text"
              className="bm-AuthModal__formControl mb-3"
              placeholder="Enter first name"
              autoFocus={true}
              value={currentUserName}
              onInput={this.handleFirstNameUpdate}
            />
            <Form.Control
              type="text"
              className="bm-AuthModal__formControl"
              placeholder="Enter last name"
              autoFocus={false}
              value={currentUserLastName}
              onInput={this.handleLastNameUpdate}
            />
            {Boolean(nameErrorMessage) && (
              <div className="mt-2" style={{ width: '100%' }}>
                <p className="m-0 p-0" style={{ color: Colors.danger }}>{nameErrorMessage}</p>
              </div>
            )}
          </div>
        ),
      },
    ];
    
    return (
      <Modal
        size="lg"
        className="bm-AuthModal bm-Modal--mobile text-white"
        contentClassName="bg-secondaryButton"
        scrollable={true}
        centered={true}
        show={show}
        onShow={onShow}
        onHide={this.onClose}
        backdrop={phoneStep === PhoneStep.profile ? 'static' : true}
      >
        <div className="d-flex justify-content-end pt-3 pr-2">
          <button onClick={this.onClose} className="btn pr-0">
            <span className="material-icons text-white">close</span>
          </button>
        </div>
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Logo className="d-block mb-4" width="40%" fill="#fff" style={{ height: 75 }} />
            <div className="text-center mb-3" style={{ maxWidth: '75%' }}>
              <h5 className="m-0 p-0">{header}</h5>
              {Boolean(subheader) && <h6 className="text-secondary mt-2 m-0 p-0" style={{ fontSize: 14 }}>{subheader}</h6>}
            </div>
            {Boolean(phoneStep) ? (
              <form
                onSubmit={(e: FormEvent) => {
                  e && e.preventDefault();
                  e && e.stopPropagation();

                  this.handlePhoneNumberSignIn();
                }}
                style={{ width: '100%' }}
              >
                <div className="container d-flex justify-content-center pt-3 pb-3" style={{ width: '100%' }}>
                  <div className="bm-AuthModal__stepper">
                    <Stepper
                      steps={phoneSteps}
                      activeStepIndex={phoneStepToIndex[phoneStep!]}
                      showHeader={false}
                    />
                    {phoneNumberLoading ? (
                      <div>
                        <Spinner size="small" variant="light" />
                      </div>
                    ) : (
                      <Button
                        type="button"
                        variant="primary"
                        className="btn-block"
                        onClick={this.handlePhoneNumberSignIn}
                        style={{
                          width: '100%',
                          borderRadius: 30,
                          fontWeight: 700,
                          height: 45,
                          color: Colors.white,
                          backgroundColor: Colors.primary,
                        }}
                      >
                        Continue
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            ) : (
              <div className="bm-AuthModal__buttonGroup pt-4 mb-5" style={{ width: '100%' }}>
                <div className="mb-3">
                  <ConnectToFacebookButton onSuccess={onSuccess} />
                </div>
                {/* <div className="mb-3">
                  <ConnectToAppleButton onSuccess={onSuccess} />
                </div> */}
                <div className="mb-3">
                  <Button
                    className="text-white pl-2 pr-2 d-flex justify-content-center align-items-center"
                    variant="secondary"
                    onClick={this.showPhoneNumberStep}
                    style={{ borderRadius: 30, fontWeight: 700, height: 45, width: '100%' }}
                  >
                    <Icon size={24} solid name={Icons.phone} style={{ marginRight: 10 }} />
                    {/* <i className="fa fa-phone text-dark" style={{ fontSize: 24, marginRight: 10 }} /> */}
                    <h5 className="text-bold text-dark p-0 m-0" style={{ fontSize: '1.15rem' }}>Continue with phone</h5>
                  </Button>
                </div>
              </div>
            )}
            {/* <Button variant="link">Use Another Option</Button> */}
            <p className="text-center mt-3 pl-2 pr-2" style={{ fontSize: 11 }}>
              I acknowledge that I have read and agree to the{' '}
              <u className="text-white" style={{ textDecorationColor: Colors.white }}>
                <Link className="text-white" to="/terms-and-conditions" target="_blank" rel="noreferrer">
                  Terms and Conditions
                </Link>
              </u>{' '}
              and{' '}
              <u className="text-white" style={{ textDecorationColor: Colors.white }}>
                <Link className="text-white" to="/privacy-policy" target="_blank" rel="noreferrer">
                  Privacy Policy
                </Link>
              </u>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCurrentUser: (user: User) => { dispatch(updateCurrentUser(user)); },
  addNotification: (notification: Omit<Notification, 'id'>) => {
    addNotification(notification);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);

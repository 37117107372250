/* Internal dependencies */
import React, { FormEvent, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import get from 'lodash/get';
import { Form, Modal, Spinner } from 'react-bootstrap';

/* Internal dependencies */
import { ApplicationState } from 'src/store';
import { getEvent } from 'src/store/ducks/events';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import Button from 'src/button/Button';
import { addNotification } from 'src/store/ducks/notifications';
import { Notification } from 'src/types/Notification';
import './BuyButton.scss';
import Icon, { Icons } from 'src/icon/Icon';
import Colors from 'src/colors';
import CheckoutTicket from './CheckoutTicket';
import Stepper, { Step, StepperHeader } from 'src/stepper/Stepper';
import CheckoutPayment from './CheckoutPayment';
import CheckoutComplete from './CheckoutComplete';
import { isTicketAvailable } from 'src/store/helpers/events';
import UserDefaultAvatar from 'src/user/UserDefaultAvatar';
import Promotion from 'src/types/Promotion';
import { redeemPromoCode } from 'src/api/promotions';
import DoneButton from 'src/button/DoneButton';
import AuthModalButton from '../authModal/AuthModalButton';

type OwnProps = {
  event: any;
  buyText?: string;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  modalOpen: boolean;
  activeStepIndex: number;
  activeTicketIndex: string;
  ticketQuantity: number;
  promoCode?: string;
  promoCodeModal: boolean;
  activePromotion?: Promotion;
  promotionErrorMessage?: string;
  applylPromotionLoading: boolean;
};

const INITIAL_STATE = {
  activeTicketIndex: '0',
  ticketQuantity: 1,
  modalOpen: false,
  activeStepIndex: 0,
  promoCode: '',
  promoCodeModal: false,
  activePromotion: undefined,
  promotionErrorMessage: undefined,
  applylPromotionLoading: false,
};

const BuyButton: React.FC<Props> = ({
  currentUser,
  event,
  buyText = 'Buy now',
}) => {
  const eventId = get(event, 'id');
  const tickets = get(event, 'tickets');
  const ticketItems = get(tickets, 'items', []);
  const [cheapestAvailableTicket] = ticketItems.filter(isTicketAvailable);
  const cheapestAvailableTicketPrice = get(cheapestAvailableTicket, 'priceValue');
  const cheapestAvailableTicketIndex = ticketItems.findIndex(({ id }: any) => id === get(cheapestAvailableTicket, 'id'));
  const [activeTicketIndex, setActiveTicketIndex] = useState<State['activeTicketIndex']>(cheapestAvailableTicketIndex === -1 ? INITIAL_STATE['activeTicketIndex'] : `${cheapestAvailableTicketIndex}`);
  const [ticketQuantity, setTicketQuantity] = useState<State['ticketQuantity']>(INITIAL_STATE['ticketQuantity']);
  const [modalOpen, setModalOpen] = useState<State['modalOpen']>(INITIAL_STATE['modalOpen']);
  const [activeStepIndex, setActiveStepIndex] = useState<State['activeStepIndex']>(INITIAL_STATE['activeStepIndex']);
  const eventImageUrl = get(event, 'images[0].url');
  const [promoCode, setPromoCode] = useState<State['promoCode']>(INITIAL_STATE['promoCode']);
  const [promoCodeModal, setPromoCodeModal] = useState<State['promoCodeModal']>(INITIAL_STATE['promoCodeModal']);
  const [activePromotion, setActivePromotion] = useState<State['activePromotion']>(undefined);
  const [promotionErrorMessage, setPromotionErrorMessage] = useState<State['promotionErrorMessage']>(undefined);
  const [applylPromotionLoading, setApplylPromotionLoading] = useState<State['applylPromotionLoading']>(false);
  
  const openPromoCodeModal = () => { setPromoCodeModal(true); };
  const closePromoCodeModal = () => {
    setPromoCodeModal(false);
    setPromoCode('');
    setPromotionErrorMessage(undefined);
  };

  const clearActivePromotion = () => { setActivePromotion(undefined); };

  const resetState = () => {
    // setActiveTicketIndex(cheapestAvailableTicketIndex);
    setActiveStepIndex(INITIAL_STATE['activeStepIndex']);
    setTicketQuantity(INITIAL_STATE['ticketQuantity']);
    setActivePromotion(INITIAL_STATE['activePromotion']);
  };

  const showModal = () => { setModalOpen(true); };

  const hideModal = () => {
    setModalOpen(false);
    resetState();
  };

  const handleBuy = async () => {
    showModal();
  };

  const incrementStepIndex = useCallback(() => {
    setActiveStepIndex(activeStepIndex + 1);
  }, [activeStepIndex]);

  const decrementStepIndex = useCallback(() => {
    if (!activeStepIndex || activeStepIndex < 1) {
      hideModal();
      return;
    };

    setActiveStepIndex(activeStepIndex - 1);
  }, [activeStepIndex]);

  const handleApplyPromotion = async (e: FormEvent) => {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!promoCode) {
      setPromotionErrorMessage('Must enter a promo code to continue.');
      return;
    }

    try {
      setApplylPromotionLoading(true);
      const { valid, promotion, message } = await redeemPromoCode(promoCode, eventId);
      
      if (valid) {
        setActivePromotion(promotion);
        closePromoCodeModal();
      } else {
        setPromotionErrorMessage(message);
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setApplylPromotionLoading(false);
    }
  };

  const steps: Step[] = useMemo(() => [
    {
      title: 'Select tickets',
      mobileTitle: 'Tickets',
      content: (
        <CheckoutTicket
          event={event}
          quantity={ticketQuantity}
          activeTicketIndex={activeTicketIndex}
          onActiveTicketIndexChange={setActiveTicketIndex}
          onQuantityChange={setTicketQuantity}
          incrementStepIndex={incrementStepIndex}
          jumpStepIndex={setActiveStepIndex}
          activePromotion={activePromotion}
          clearActivePromotion={clearActivePromotion}
        />
      ),
      allowJumpTo: Boolean(activeStepIndex !== 2),
    },
    {
      title: 'Complete payment',
      mobileTitle: 'Payment',
      content: (
        <CheckoutPayment
          event={event}
          ticket={ticketItems[activeTicketIndex]}
          quantity={ticketQuantity}
          incrementStepIndex={incrementStepIndex}
          activePromotion={activePromotion}
        />
      ),
      allowJumpTo: Boolean(activeStepIndex !== 2),
    },
    {
      title: 'Get the app',
      content: (
        <CheckoutComplete
          event={event}
          ticket={ticketItems[activeTicketIndex]}
          quantity={ticketQuantity}
        />
      ),
    },
  ], [
    event, activeTicketIndex, ticketQuantity,
    ticketItems, incrementStepIndex,
    setActiveTicketIndex, activePromotion,
    clearActivePromotion,
  ]);

  const promoCodeModalComponent = (
    <Modal
      className="bm-BuyButton__promoCodeModal bm-Modal--mobile"
      contentClassName="bg-secondaryButton"
      show={promoCodeModal}
      onShow={openPromoCodeModal}
      onHide={closePromoCodeModal}
      centered={true}
      scrollable={true}
      size="lg"
    >
      <form
        onSubmit={handleApplyPromotion}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
            <h3 className="text-bold text-white">Apply promo code</h3>
            <button onClick={closePromoCodeModal} className="btn pr-0">
              <span className="material-icons text-white">close</span>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
        <div>
          <div className="mb-4">
            <Form.Control
              className="mb-2"
              autoFocus={true}
              value={promoCode}
              placeholder="Enter promo code"
              onInput={(e: FormEvent) => {
                const promoCode = get(e, 'target.value', '');
            
                setPromoCode(promoCode);
              }}
              style={{
                color: Colors.primaryText,
                // fontSize: sizeToFontSize['h5'],
                backgroundColor: Colors.backgroundOverlay,
                borderRadius: 10,
                borderWidth: 0,
                height: 50,
              }}
            />
            {!Boolean(promotionErrorMessage) && <p className="m-0 p-0" style={{ color: Colors.inactiveTab }}>Access discounts or exclusive tickets</p>}
            {Boolean(promotionErrorMessage) && <p className="m-0 p-0" style={{ color: Colors.danger }}>{promotionErrorMessage}</p>}
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <DoneButton
            asyncOnClick={handleApplyPromotion as any}
            render={(onPress, loading) => (
              <Button
                variant="secondary"
                className="pl-4 pr-4 pt-2"
                onClick={onPress}
                style={{
                  backgroundColor: Colors.white,
                  borderRadius: 100,
                }}
              >
                {Boolean(applylPromotionLoading || loading) ? <div style={{ maxHeight: 30 }}><Spinner size="sm" /></div>  : (
                  <p className="text-bold m-0 p-0" color={Colors.background}>Apply</p>
                )}
              </Button>
            )}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );

  const modal = (
    <Modal
      className="bm-BuyButton__modal"
      show={modalOpen}
      onShow={() => { setModalOpen(true); }}
      onHide={() => { setModalOpen(false); }}
      centered={true}
      animation
      size="lg"
      style={{ backgroundColor: Colors.black }}
      scrollable={true}
    >
      <Modal.Body style={{ backgroundColor: Colors.black }}>
        <div
          style={{
            height: '100%',
            width: '100%',
            minHeight: '100vh',
            minWidth: '100vw',
            // maxHeight: '100vh',
            maxWidth: 'none',
            overflow: 'hidden',
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75), rgba(6, 7, 16, 1), rgba(6, 7, 16, 1)), url(${eventImageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            filter: 'blur(15px)',
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: -75,
            left: 0,
            width: '100%',
            background: 'linear-gradient(rgba(6, 7, 16, 0.35), rgba(6, 7, 16, 0.45), rgba(6, 7, 16, 1))',
            paddingTop: 75,
            height: '100%',
          }}
        >
          <div className="d-flex flex-column justify-content-between p-4" style={{ height: '100%', width: '100%', maxWidth: '100vw' }}>
            {/* Header */}
            <div className="bm-BuyButton__header d-flex justify-content-between align-items-center" style={{ height: 50, width: '100%' }}>
              <div className="bm-BuyButton__backButton" style={{ flex: 1 }}>
                <Button
                  className="mr-3"
                  onClick={activeStepIndex === steps.length - 1 ? hideModal : decrementStepIndex}
                  // variant="secondary"
                  style={{ borderRadius: 100, backgroundColor: Colors.transparent, borderWidth: 2 }}
                >
                  <div className="d-flex align-items-center">
                    <div>
                      <Icon className="bm-Icon--white" name={activeStepIndex === steps.length - 1 ? Icons.cross : Icons.chevronLeft} size={22} />
                    </div>
                    {Boolean(activeStepIndex !== steps.length - 1) && (
                      <span className="d-none d-lg-block text-white ml-1">
                        Back
                      </span>
                    )}
                  </div>
                </Button>
              </div>
              <div className="bm-BuyButton__stepperHeader d-none d-md-flex flex-column justify-content-center align-items-center text-white" style={{ flex: 1, width: '100%' }}>
                <StepperHeader
                  steps={steps}
                  activeStepIndex={activeStepIndex}
                  onStepChange={setActiveStepIndex}
                />
              </div>
              <div className="bm-BuyButton__rightHeader d-flex justify-content-end" style={{ flex: 1 }}>
                <div className="d-flex align-items-center">
                  {Boolean(activeStepIndex === 0 ) && (
                    <>
                      {!Boolean(activePromotion) && (
                        <AuthModalButton
                          modalHeader={`Register / Sign in to enter a promo code`}
                          modalSubheader="Get discounted or exclusive tickets with your code."
                          onClick={openPromoCodeModal}
                          render={(onClick) => (
                            <Button
                              variant="secondary"
                              className="pl-4 pr-4 pt-3 pb-3 mr-2"
                              onClick={onClick}
                              style={{
                                backgroundColor: Colors.secondaryButton,
                                borderRadius: 100,
                                borderWidth: 0,
                              }}
                            >
                              <h6 className="text-bold text-white m-0 p-0">Enter code</h6>
                            </Button>
                          )}
                        />
                      )}
                    </>
                  )}
                  {Boolean(currentUser) && (
                    <div
                      className="bm-BuyButton__currentUser d-flex align-items-center m-0 p-0 pl-2 pr-2 pt-2 pb-2"
                      style={{ border: '2px solid #FFFFFF', borderRadius: 100, padding: 0, margin: 0 }}
                    >
                      {get(currentUser, 'images[0]') ? (
                        <img
                          alt={get(currentUser, 'name')}
                          className="bm-BuyButton__currentUserImage img img-fluid rounded-circle"
                          src={get(currentUser, 'images[0].url')}
                          width={30}
                          height={30}
                          style={{ width: 30, height: 30, objectFit: 'cover' }}
                        />
                      ) : (
                        <UserDefaultAvatar
                          user={currentUser!}
                          className="bm-BuyButton__currentUserImage"
                          size={30}
                          style={{ width: 30, height: 30, objectFit: 'cover' }}
                        />
                      )}
                      <h6 className="d-none d-md-block text-white m-0 p-0">{get(currentUser, 'name')}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="bm-BuyButton__stepperHeader d-flex d-md-none flex-column justify-content-center align-items-center text-white" style={{ width: '100%' }}>
              <StepperHeader
                steps={steps}
                activeStepIndex={activeStepIndex}
                onStepChange={setActiveStepIndex}
              />
            </div>
            <div style={{ flex: 1, width: '100%' }}>
              <Stepper
                steps={steps}
                activeStepIndex={activeStepIndex}
                showHeader={false}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  return (
    <>
      <Button onClick={handleBuy} variant="primary" style={{ borderRadius: 100 }}>{Boolean(cheapestAvailableTicketPrice === 0) ? 'RSVP' : buyText}</Button>
      {Boolean(modalOpen) && modal}
      {promoCodeModalComponent}
    </>
  );
};

const mapStateToProps = (state: ApplicationState, { event }: OwnProps) => {
  const eventId = get(event, 'id');

  return {
    currentUser: getCurrentUser(state),
    event: getEvent(state, eventId) || event,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => {
    dispatch(addNotification(notification));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyButton);
/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import { addVenueForUser, removeVenueForUser, getVenue as getVenueApi } from '../../api/venues';
import { select, put } from '../reduxStory';
import { getCurrentUser, updateCurrentUser } from '../ducks/currentUser';
import { addNotification } from '../../store/ducks/notifications';
import { updateVenue } from '../ducks/venues';
import { cleanObject } from 'src/common/cleanObject';

export const addVenue = async (venue: any) => {
  const currentUser = select(getCurrentUser);
  const currentUserVenues = get(currentUser, 'venues', []);

  if (!currentUser) return;
  
  try {
    venue.followed = true;
    put(updateVenue(venue));
    put(
      updateCurrentUser({
        ...currentUser,
        venues: [...currentUserVenues, venue],
      })
    );
    
    return await addVenueForUser(venue);
  } catch {
    venue.followed = false;
    put(updateVenue(venue));
    put(updateCurrentUser({ ...currentUser }));
  }
};

export const removeVenue = async (venue: any) => {
  const currentUser = select(getCurrentUser);
  const currentUserVenues = get(currentUser, 'venues', []);

  if (!currentUser) return;

  const { id } = venue;
  const venueIndex = currentUserVenues.findIndex(({ id: venueId }) => id === venueId);

  try {
    venue.followed = false;
    put(updateVenue(venue));
    put(
      updateCurrentUser(
        cleanObject({
          ...currentUser,
          venues:
            venueIndex !== -1
              ? [...currentUserVenues.slice(0, venueIndex), ...currentUserVenues.slice(venueIndex + 1)]
              : undefined,
        })
      )
    );
    return await removeVenueForUser(venue);
  } catch {
    venue.followed = true;
    put(updateVenue(venue));
  }
};

export const toggleVenue = async (venue: any) => {
  const followed = get(venue, 'followed', false);

  if (Boolean(followed)) {
    put(
      addNotification({
        title: 'Venue unfollowed',
        message: `You unfollowed ${get(venue, 'name', 'the venue')}.`,
        items: [venue],
        variant: 'primary',
      })
    );
    removeVenue(venue);
  } else {
    addVenue(venue);
    put(
      addNotification({
        title: 'Venue followed',
        message: `You followed ${get(venue, 'name', 'the venue')}.`,
        items: [venue],
        variant: 'primary',
      })
    );
  }
};

export const getVenue = async (venueId: string, options: { cache: boolean; } = { cache: true }) => {
  const venue = await getVenueApi(venueId, options);
  
  put(updateVenue(venue));

  return venue;
};

/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import { modelUser } from '../store/helpers/users';
import { PaginationOptions } from 'src/types/Pagination';
import API from '.';
import { cacheify } from 'src/helpers/cacheify';
import { cleanObject } from 'src/common/cleanObject';

export const browseView = async ({ limit, offset, latitude, longitude }: PaginationOptions & { latitude: number; longitude: number }) => {
  const response = await API.get({
    path: '/search/browseView',
    options: {
      queryParams: {
        latitude,
        longitude,
        limit,
        offset,
      },
    },
  }) as any;

  const { items = [] } = response;
  items.forEach((collection: any) => {
    if (collection.items) {
      collection.items = collection.items.map((item: any) => {
        if (item.type === 'user') {
          return modelUser(item);
        }

        return item;
      });
    }
  });

  return response;
};

const cachedSearch = cacheify<(...args: any[]) => Promise<{ items: any[] }>, { items: any[] }>(
  async (q: string, options: PaginationOptions) =>
    await API.get({
      path: '/search',
      options: {
        queryParams: cleanObject({
          ...options,
          q,
          global: get(options, 'global', true),
        }),
      },
    }),
  60000
);

export const search = async (q: string, options: PaginationOptions) => await cachedSearch()(q, options);

export const searchQueryView = async (options: PaginationOptions & { q: string; latitude: number; longitude: number; type?: string; }) => {
  const response = await API.get({
    path: '/search/searchQueryView',
    options: {
      queryParams: cleanObject(options),
    },
  });

  return response;
};

/* External dependencies */
import React, { Suspense, lazy, useEffect } from 'react';
import { connect, Provider, ReactReduxContext } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Cache } from 'aws-amplify/utils';
import { Helmet } from 'react-helmet';
import Cognito from 'aws-sdk/clients/cognitoidentity';
import { setUserProperties, setUserId } from 'firebase/analytics';
import AOS from 'aos';
import branch from 'branch-sdk';
import { ConnectedRouter as Router } from 'connected-react-router';
import get from 'lodash/get';

/* Internal dependencies */
import amplifyInit from './aws-exports';
import './App.scss';
import store, { ApplicationState, history } from './store';
import { ViewTasteRedirect } from './tasteTest/ViewTaste';
import {
  updateCurrentUser,
  CurrentUserState,
  updateCurrentUserLoading,
  getCurrentUser,
} from './store/ducks/currentUser';
import { getCurrentFullUser } from './api/users';
import { modelUser } from './store/helpers/users';
import ScrollToTop from './scrollToTop/ScrollToTop';
import Navbar from './navbar/Navbar';
import NotificationsContainer from './notifications/NotificationsContainer';
import { analytics } from './firebase';
import Spinner from './spinner/Spinner';
import VerificationApplication from './verification/VerificationApplication';
import Analytics from './analytics/Analytics';
import { fetchAll } from './common/helpers/fetchAll';
import { getAuthenticatedUser, getIdentityId } from './auth';
import RouteTracker from './analytics/RouteTracker';

const EditTasteTest = lazy(() => import('./tasteTest/EditTasteTest'));
const ViewTaste = lazy(() => import('./tasteTest/ViewTaste'));
const ViewResult = lazy(() => import('./tasteTest/ViewResult'));
const TastemakersCampus = lazy(() => import('./ambassadors/TastemakersCampus'));
const About = lazy(() => import('./about/About'));
const Blog = lazy(() => import('./blog/Blog'));
const Post = lazy(() => import('./blog/Post'));
const AuthLandingPage = lazy(() => import('./auth/AuthLandingPage'));
const Admin = lazy(() => import('./admin/Admin'));
const ViewUser = lazy(() => import('./admin/ViewUser'));
const ExitModal = lazy(() => import('./subscribe/ExitModal'));
const TermsAndConditions = lazy(() => import('./legal/TermsAndConditions'));
const EndUserLicenseAgreement = lazy(() => import('./legal/EndUserLicenseAgreement'));
const PrivacyPolicy = lazy(() => import('./legal/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('./legal/CookiePolicy'));
const Beyhive = lazy(() => import('./fandoms/Beyhive'));
const BTSArmy = lazy(() => import('./fandoms/BTSArmy'));
const Beliebers = lazy(() => import('./fandoms/Beliebers'));
const Home = lazy(() => import('./home/Home'));
const Events = lazy(() => import('./events/Events'));
const Event = lazy(() => import('./events/Event'));
const ArtistDetail = lazy(() => import('./artist/ArtistDetail'));
const ManageArtist = lazy(() => import('./artist/manage/ManageArtist'));
const Activity = lazy(() => import('./activities/Activity'));
const Locations = lazy(() => import('./locations/Locations'));
const Location = lazy(() => import('./locations/Location'));
const Neighborhood = lazy(() => import('./neighborhoods/Neighborhood'));
const Venue = lazy(() => import('./venues/Venue'));
const CreateEvent = lazy(() => import('./events/manage/CreateEvent'));
const ManageEvent = lazy(() => import('./events/manage/ManageEvent'));
const Community = lazy(() => import('./communities/Community'));
const ManageCommunity = lazy(() => import('./communities/manage/ManageCommunity'));
const CreateCommunity = lazy(() => import('./communities/manage/CreateCommunity'));
const Orders = lazy(() => import('./orders/Orders'));
const Order = lazy(() => import('./orders/Order'));
const Explore = lazy(() => import('./explore/Explore'));
const MyCommunities = lazy(() => import('./me/MyCommunities'));
const MyEvents = lazy(() => import('./me/MyEvents'));
const FeedPost = lazy(() => import('./posts/Post'));
const CollectionDetail = lazy(() => import('./collection/CollectionDetail'));

const urlParams = new URLSearchParams(window.location.search);
const testMode = urlParams.get('testMode') === 'true' ? true : false;
export const STRIPE_TEST_MODE = testMode;

// Initialize AWS Amplify client
amplifyInit();
AOS.init({ offset: 125, delay: 50, duration: 400, once: true });
const branchOptions = {
  open_app: true,
} as any;
branch.init(process.env.REACT_APP_BRANCH_KEY!, branchOptions, (err, data) => {
  console.log(err, data);

  if (document && document.querySelectorAll("meta[name='branch:deeplink:$deeplink_path']").length > 0) {
    const element = document!.querySelector("meta[name='branch:deeplink:$deeplink_path']");
    
    if (element) {
      const meta = element.getAttribute("content");

      branch.setBranchViewData({
        data:{
          '$deeplink_path': meta!,
        },
      });
    }
  }
});

type StateProps = {
  currentUser: CurrentUserState['user'];
  children?: React.ReactNode;
};

type Props = StateProps;

const cognitoIdentity = new Cognito({ region: 'us-west-2' });

export const NotFound = () => (
  <>
    <Helmet>
      <title>Beamatch | Not Found - 404</title>
    </Helmet>
    <Navbar />
    <div className="container d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
      <h2>Uh oh. You're way off beat!</h2>
      <div>
        <p className="lead">The page you requested cannot be found. Try another page.</p>
      </div>
    </div>
  </>
);

export const getSignedInStatusWithoutUser = async () => {
  try {
    let [authUser] = await Promise.all([
      getAuthenticatedUser(),
    ]);

    const authenticated = get(authUser, 'authenticated');
    let identityId = get(authUser, 'identityId');

    if (!identityId) {
      const token = await Cache.getItem(`fbToken_${process.env.NODE_ENV}`);
      const { IdentityId } = await cognitoIdentity
        .getId({
          AccountId: process.env.REACT_APP_ACCOUNT_ID || '',
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || '',
          Logins: {
            'graph.facebook.com': token,
          },
        })
        .promise();
      identityId = IdentityId!;
    }

    // console.log('getSignedInStatusWithoutUser authUser', authUser);
    console.log('getSignedInStatusWithoutUser authenticated', authenticated);
    console.log('getSignedInStatusWithoutUser identityId', identityId);

    if (authenticated && identityId) {
      const userId = `user:${identityId}`;
      const user = await getCurrentFullUser(userId);
      const modeledUser = modelUser(user);
      store.dispatch(updateCurrentUser(modeledUser));

      if (user) {
        setUserProperties(analytics, user);
        setUserId(analytics, userId);
        Analytics.identify(userId, user);
      }
      
      return true;
    }
  } catch (e: any) {
    console.log('authentication error', e);
    console.log('authentication error message', e.message);
  }
  return false;
};

const AppWrapper: React.FC<Props> = ({ currentUser, children }) => {
  const currentUserId = get(currentUser, 'id');

  useEffect(() => {
    const load = async () => {
      try {
        let identityId = await getIdentityId();

        if (currentUserId) {
          await Analytics.identify(currentUserId, currentUser!);

          return;
        }

        console.log('AppWrapper componentDidMount identityId', identityId);

        if (identityId) {
          const userId = `user:${identityId}`;
          console.log('authenticated user ID', userId);
          const user = await getCurrentFullUser(userId);

          console.log('authenticated user', user);

          if (user && user.id) {
            const modeledUser = modelUser(user);
            store.dispatch(updateCurrentUser(modeledUser));
            setUserProperties(analytics, user);
            setUserId(analytics, user.id);
            await fetchAll([
              Analytics.identify(userId, modeledUser),
            ]);
          }
        }
      } catch (e: any) {
        console.log('authentication error', e);
        console.log('authentication error message', e.message);
      } finally {
        store.dispatch(updateCurrentUserLoading(false));
      }

      if (currentUser && currentUser.id) {
        Analytics.identify(currentUser.id, currentUser);
      }
    }

    load();
  }, []);

  useEffect(() => {
    if (currentUserId) {
      Analytics.identify(currentUserId, currentUser!);
    }
  }, [currentUserId]);

  return children as any;
};

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

const AppContainer = connect(mapStateToProps)(AppWrapper) as any;

class App extends React.Component {
  async componentDidMount() {
    this.registerStorageListener();
  }

  componentWillUnmount(): void {
    this.unregisterStorageListener();
  }

  handleQuotaExceededError = (e: Event) => {
    if (e instanceof DOMException && e.name === 'QuotaExceededError') {
      console.warn('LocalStorage quota exceeded. Clearing storage.');
      localStorage.clear();
    }
  };

  registerStorageListener = () => {
    if (window) {
      window.addEventListener('error', this.handleQuotaExceededError);
    }
  };

  unregisterStorageListener = () => {
    // Remove the event listener
    window.removeEventListener('error', this.handleQuotaExceededError);
  };

  updateCurrentUser = (user: CurrentUserState['user']) => {
    store.dispatch(updateCurrentUser(user));
  };

  render() {
    return (
      <Provider store={store} context={ReactReduxContext}>
        <AppContainer>
          <>
            <Router history={history}>
              <RouteTracker />
              <Suspense
                fallback={(
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: -75, minHeight: '100vh', height: '100%', backgroundColor: '#060710' }}
                  >
                    <Spinner />
                  </div>
                )}
              >
                <div id="bm" style={{ minHeight: '100vh', height: '100%' }}>
                  <Switch>
                    <Route exact key="home" path="/" component={Home} />
                    <Route exact key="fans" path="/fans" component={Home} />
                    <Route exact key="creators" path="/creators" component={Home} />
                    <Route exact key="myCommunities" path="/me/communities" component={MyCommunities} />
                    <Route exact key="myCommunities-tabKey" path="/me/communities/:tabKey" component={MyCommunities} />
                    <Route exact key="myEvents" path="/me/events" component={MyEvents} />
                    <Route exact key="myEvents-tabKey" path="/me/events/:tabKey" component={MyEvents} />
                    <Route exact key="beyhive" path="/beyhive" component={Beyhive} />
                    <Route exact key="bts-army" path="/bts-army" component={BTSArmy} />
                    <Route exact key="beliebers" path="/beliebers" component={Beliebers} />
                    <Route path="/taste" key="viewTasteRedirect" exact={true} component={ViewTasteRedirect} />
                    <Route path="/users/verification" key="userVerification" exact={true} component={VerificationApplication} />
                    <Route
                      key="viewTaste"
                      path="/taste/user:us-west-2::userId/preview"
                      exact={true}
                      component={({
                        match: {
                          params: { userId },
                        },
                      }: any) => <Redirect to={`/taste/${userId}/preview`} />}
                    />
                    <Route key="viewTaste" path="/taste/:userId/preview" exact={true} component={ViewTaste} />
                    <Route
                      key="viewTaste"
                      path="/taste/user:us-west-2::userId"
                      exact={true}
                      component={({
                        match: {
                          params: { userId },
                        },
                      }: any) => <Redirect to={`/taste/${userId}`} />}
                    />
                    <Route key="viewTaste" path="/taste/:userId" exact={true} component={ViewTaste} />
                    <Route
                      key="viewResult1"
                      path="/taste/user:us-west-2::userId/results/result::resultId"
                      exact={true}
                      component={({
                        match: {
                          params: { userId, resultId },
                        },
                      }: any) => <Redirect to={`/taste/${userId}/results/${resultId}`} />}
                    />
                    <Route
                      key="viewResult2"
                      path="/taste/:userId/results/result::resultId"
                      exact={true}
                      component={({
                        match: {
                          params: { userId, resultId },
                        },
                      }: any) => <Redirect to={`/taste/${userId}/results/${resultId}`} />}
                    />
                    <Route
                      key="viewResultTab"
                      path="/taste/:userId/results/:resultId/:tab"
                      exact={true}
                      component={ViewResult}
                    />
                    <Route
                      key="viewResult3"
                      path="/taste/:userId/results/:resultId"
                      exact={true}
                      component={(props: any) => <ViewResult {...props} />}
                    />
                    <Route
                      key="ambassadors"
                      path="/ambassadors/campus-tastemakers"
                      exact={true}
                      component={TastemakersCampus}
                    />
                    <Route key="explore" path="/explore" exact={true} component={Explore} />
                    <Route key="events" path="/events" exact={true} component={Events} />
                    <Route key="createEvent" path="/events/new" exact={true} component={CreateEvent} />
                    <Route key="event" path="/events/:providerCode/:eventId" exact={true} component={Event} />
                    <Route key="event-short" path="/e/:providerCode/:eventId" exact={true} component={Event} />
                    <Route key="event-short-slug" path="/e/:providerCode/:eventId/:slug" exact={true} component={Event} />
                    <Route key="event-slug" path="/events/:providerCode/:eventId/:slug" exact={true} component={Event} />
                    <Route key="manageEvent" path="/event/:providerCode/:eventId/manage" exact={true} component={ManageEvent} />
                    <Route key="manageEvent-tabKey" path="/event/:providerCode/:eventId/manage/:tabKey" exact={true} component={ManageEvent} />
                    <Route key="manageEvent-tabKey-sectionId" path="/event/:providerCode/:eventId/manage/:tabKey/:sectionId" exact={true} component={ManageEvent} />
                    <Route key="createCommunity" path="/communities/new" exact={true} component={CreateCommunity} />
                    <Route key="community" path="/communities/:communityId" exact={true} component={Community} />
                    <Route key="community-short" path="/c/:communityId" exact={true} component={Community} />
                    {/* <Route key="community-short-slug" path="/c/:communityId/:slug" exact={true} component={Community} />
                    <Route key="community-slug" path="/communities/:communityId/:slug" exact={true} component={Community} /> */}
                    <Route key="collection" path="/collections/:collectionId" exact={true} component={CollectionDetail} />
                    <Route key="collection-short" path="/co/:collectionId" exact={true} component={CollectionDetail} />
                    <Route key="collection-short-slug" path="/co/:collectionId/:slug" exact={true} component={CollectionDetail} />
                    <Route key="collection-slug" path="/collections/:collectionId/:slug" exact={true} component={CollectionDetail} />
                    <Route key="do-list" path="/do-lists/:collectionId" exact={true} component={CollectionDetail} />
                    <Route key="do-list-short" path="/d/:collectionId" exact={true} component={CollectionDetail} />
                    <Route key="do-list-short-slug" path="/d/:collectionId/:slug" exact={true} component={CollectionDetail} />
                    <Route key="do-list-slug" path="/do-lists/:collectionId/:slug" exact={true} component={CollectionDetail} />
                    <Route key="manageCommunity" path="/community/:communityId/manage" exact={true} component={ManageCommunity} />
                    <Route key="manageCommunity-tabKey" path="/community/:communityId/manage/:tabKey" exact={true} component={ManageCommunity} />
                    <Route key="manageCommunity-tabKey-sectionId" path="/community/:communityId/manage/:tabKey/:sectionId" exact={true} component={ManageCommunity} />
                    <Route key="community-tabKey" path="/communities/:communityId/:tabKey" exact={true} component={Community} /> {/* Route ordering matters here */}
                    <Route key="tickets" path="/tickets" exact={true} component={Orders} />
                    <Route key="ticket" path="/tickets/:orderId" exact={true} component={Order} />
                    <Route key="locations" path="/cities" exact={true} component={Locations} />
                    <Route key="location" path="/cities/:locationId" exact={true} component={Location} />
                    <Route key="location-slug" path="/cities/:locationId/:slug" exact={true} component={Location} />
                    <Route key="location-slug-tabKey" path="/cities/:locationId/:slug/:tabKey" exact={true} component={Location} />
                    <Route key="neighborhood" path="/neighborhoods/:neighborhoodId" exact={true} component={Neighborhood} />
                    <Route key="neighborhood-slug" path="/neighborhoods/:neighborhoodId/:slug" exact={true} component={Neighborhood} />
                    <Route key="venue" path="/venues/:venueId" exact={true} component={Venue} />
                    <Route key="venue-short" path="/v/:venueId" exact={true} component={Venue} />
                    <Route key="venue-slug" path="/venues/:venueId/:slug" exact={true} component={Venue} />
                    <Route key="venue-slug-short" path="/v/:venueId/:slug" exact={true} component={Venue} />
                    <Route key="location-slug" path="/cities/:locationId/:slug" exact={true} component={Location} />
                    <Route key="post" path="/posts/:postId" exact={true} component={FeedPost} />
                    <Route key="post-short" path="/p/:postId" exact={true} component={FeedPost} />
                    <Route key="activity" path="/activities/:activityId" exact={true} component={Activity} />
                    <Route key="meetup" path="/meetups/:activityId" exact={true} component={Activity} />
                    <Route key="plan" path="/plans/:activityId" exact={true} component={Activity} />
                    <Route key="artist" path="/artists/:providerCode/:artistId" exact={true} component={ArtistDetail} />
                    <Route key="artist-short" path="/a/:providerCode/:artistId" exact={true} component={ArtistDetail} />
                    <Route key="artist-short-slug" path="/a/:providerCode/:artistId/:slug" exact={true} component={ArtistDetail} />
                    <Route key="artist-slug" path="/artists/:providerCode/:artistId/:slug" exact={true} component={ArtistDetail} />
                    <Route key="manageArtist" path="/artist/:providerCode/:artistId/manage" exact={true} component={ManageArtist} />
                    <Route key="manageArtist-tabKey" path="/artist/:providerCode/:artistId/manage/:tabKey" exact={true} component={ManageArtist} />
                    <Route key="manageArtist-tabKey-sectionId" path="/artist/:providerCode/:artistId/manage/:tabKey/:sectionId" exact={true} component={ManageArtist} />
                    <Route key="about" path="/about" exact={true} component={About} />
                    <Route key="blogPost" path="/the-beat/:postId" exact={true} component={Post} />
                    <Route key="blogPost-slug" path="/the-beat/:postId/:slug" exact={true} component={Post} />
                    <Route key="blog" path="/the-beat" exact={true} component={Blog} />
                    <Route key="magazine" path="/magazine/category/:tagType" exact={true} component={Blog} />
                    <Route key="magazinePost" path="/magazine/:postId" exact={true} component={Post} />
                    <Route key="magazinePost-slug" path="/magazine/:postId/:slug" exact={true} component={Post} />
                    <Route key="magazine" path="/magazine" exact={true} component={Blog} />
                    <Route
                      key="editTaste1"
                      path="/taste/user:us-west-2::userId/edit"
                      exact={true}
                      component={({
                        match: {
                          params: { userId },
                        },
                      }: any) => <Redirect to={`/taste/${userId}/edit`} />}
                    />
                    <Route key="editTaste2" path="/taste/:userId/edit" exact={true} component={EditTasteTest} />
                    <Route key="sync" path="/sync" component={() => null} />
                    <Route key="terms-and-conditions" path="/terms-and-conditions" component={TermsAndConditions} />
                    <Route key="end-user-license-agreement" path="/end-user-license-agreement" component={EndUserLicenseAgreement} />
                    <Route key="privacy-policy" path="/privacy-policy" component={PrivacyPolicy} />
                    <Route key="cookie-policy" path="/cookie-policy" component={CookiePolicy} />
                    <Route key="auth/facebook" path="/auth/facebook" component={AuthLandingPage} />
                    <Route key="admin" path="/admin" exact={true} component={Admin} />
                    <Route key="admin" path="/admin/users" exact={true} component={Admin} />
                    <Route key="admin" path="/admin/users/:userId" component={ViewUser} />
                    <Route key="admin" path="/admin/users/:userId/:tab" exact={true} component={ViewUser} />
                    <Route key="notFound" component={NotFound} path="/not-found" />
                    <Route key="404" component={NotFound} />
                  </Switch>
                </div>
              </Suspense>
              <ScrollToTop />
              <NotificationsContainer />
            </Router>
          </>
        </AppContainer>
        <ExitModal />
      </Provider>
    );
  }
}

export default App;

/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import { cleanObject } from 'src/common/cleanObject';
import { PaginationOptions, PaginationResponse } from 'src/types/Pagination';
import User, { Image } from 'src/types/User';
import { modelUser } from 'src/store/helpers/users';
import Event from 'src/types/Event';
import Community from 'src/types/Community';
import API from '.';
import { cacheify } from 'src/helpers/cacheify';

export const listBeatmatchEvents = async () => await API.get({
  path: `/events/listBeatmatchEvents`,
});

export const getEvent = async (eventId: string, options?: { cache: boolean }) =>
  (await API.get({
    path: `/events/${eventId}`,
    options: {
      queryParams: cleanObject({
        cache: options && options.cache,
      }),
    },
  })) as any as Event;

export const addEventForUser = async (userId: string, event: any) =>
  await API.post({
    path: `/users/${userId}/events`,
    options: {
      body: event,
    },
  });

export const removeEventForUser = async (eventId: string, userId: string) =>
  await API.del({
    path: `/users/${userId}/events/${eventId}`,
  });

export const listEventsForUser = async (userId: string, { limit, offset }: PaginationOptions) =>
  await API.get({
    path: `/users/${userId}/events`,
    options: {
      queryParams: cleanObject({
        limit,
        offset: offset && JSON.stringify(offset),
      }),
    },
  });

export const listPastEventsForUser = async (userId: string, { limit, offset }: PaginationOptions) =>
  await API.get({
    path: `/users/${userId}/pastEvents`,
    options: {
      queryParams: cleanObject({
        limit,
        offset: Boolean(offset) ? JSON.stringify(offset) : undefined,
      }),
    },
  });

export const listCreatedEventsForUser = async (userId: string, { limit, offset }: PaginationOptions) =>
  await API.get<PaginationResponse<Event>>({
    path: `/users/${userId}/createdEvents`,
    options: {
      queryParams: cleanObject({
        limit,
        offset: offset && JSON.stringify(offset),
      }),
    },
  });

export const listEvents = async ({
  startDate,
  endDate,
  radius,
  longitude,
  latitude,
  sort,
  offset,
  limit,
}: {
  startDate: Date,
  endDate: Date,
  radius?: number;
  longitude?: number;
  latitude?: number;
  sort?: string;
} & PaginationOptions) =>
  await API.get<PaginationResponse<Event>>({
    path: `/events`,
    options: {
      queryParams: cleanObject({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        radius,
        longitude,
        latitude,
        sort,
        offset,
        limit,
      }),
    },
  });

export const listUsersForEvent = async (
  eventId: string,
  { limit, offset }: PaginationOptions
) => {
  const response = await API.get<PaginationResponse<User>>({
    path: `/events/${eventId}/users`,
    options: {
      queryParams: {
        limit,
        offset,
      },
    },
  });

  return {
    ...response,
    items: response.items && response.items.map(modelUser),
  };
};

export const listOrdersForEvent = async (
  eventId: string,
  options: PaginationOptions & { name?: string; }
) => {
  const offset = get(options, 'offset');
  const response = await API.get({
    path: `/events/${eventId}/orders`,
    options: {
      queryParams: cleanObject({
        ...options,
        offset: Boolean(offset) ? JSON.stringify(offset) : undefined,
      }),
    },
  });

  return response;
};

export const getCreateSuggestions = async (options: {
  suggestionType: 'artists' | 'genres' | 'creators' | 'tracks';
  creatorId: string;
  eventId?: string;
}) => {
  const suggestionType = get(options, 'suggestionType');
  const creatorId = get(options, 'creatorId');
  const eventId = get(options, 'eventId');

  const response = await API.get({
    path: `/events/getCreateSuggestions/${creatorId}`,
    options: {
      queryParams: cleanObject({
        suggestionType,
        eventId,
      }),
    },
  });

  return response;  
};

export const addEvent = async (event: any) =>
  await API.post<Event>({
    path: `/events`,
    options: {
      body: event,
    },
  });

export const copyEvent = async (event: any) =>
  await API.post<Event>({
    path: `/events/copyEvent`,
    options: {
      body: event,
    },
  });

export const updateEvent = async (event: any) =>
  (await API.put<Event>({
    path: `/events/${get(event, 'id')}`,
    options: {
      body: event,
    },
  })) as any as Event;

export const deleteEvent = async (eventId: string) =>
  (await API.del({
    path: `/events/${eventId}`,
  })) as any as Event;

export const updateEventPreferences = async (eventId: string, preferences: Event['preferences']) =>
  await API.put({
    path: `/events/${eventId}/preferences`,
    options: {
      body: preferences,
    },
  });

export const addImageForEvent = async (eventId: string, image: Omit<Image, 'url'>) =>
  await API.post({
    path: `/events/${eventId}/images`,
    options: {
      body: image,
    },
  });

export const updateImageForEvent = async (eventId: string, image: Omit<Image, 'url'>, imageIndex: number) =>
  await API.put({
    path: `/events/${eventId}/images/${imageIndex}`,
    options: {
      body: image,
    },
  });

export const addCreatorForEvent = async (eventId: string, { id: creatorId, creatorType }: (User | Community) & { creatorType?: string; }) =>
  await API.post({
    path: `/events/${eventId}/creators`,
    options: {
      body: cleanObject({ creatorId, creatorType }),
    },
  });

export const removeCreatorForEvent = async (eventId: string, { id: creatorId }: any) =>
  await API.del({
    path: `/events/${eventId}/creators/${creatorId}`,
  });

export const respondToCreatorInvitation = async (eventId: string, { id: creatorId, status }: any) =>
  await API.put({
    path: `/events/${eventId}/creators/${creatorId}`,
    options: {
      body: { status },
    },
  });

export const addTicketForEvent = async (eventId: string, ticket: any) =>
  await API.post({
    path: `/events/${eventId}/tickets`,
    options: {
      body: ticket,
    },
  });

export const removeTicketForEvent = async (eventId: string, { id: ticketId }: any) =>
  await API.del({
    path: `/events/${eventId}/tickets/${ticketId}`,
  });

export const updateTicketForEvent = async (eventId: string, ticket: any) =>
  await API.put({
    path: `/events/${eventId}/tickets/${get(ticket, 'id')}`,
    options: {
      body: ticket,
    },
  });

export const addArtistForEvent = async (eventId: string, { provider, providerId }: any) =>
  await API.post({
    path: `/events/${eventId}/artists`,
    options: {
      body: { provider, providerId },
    },
  });

export const removeArtistForEvent = async (eventId: string, { id: artistId }: any) =>
  await API.del({
    path: `/events/${eventId}/artists/${artistId}`,
  });

export const addTrackForEvent = async (eventId: string, track: any) =>
  await API.post({
    path: `/events/${eventId}/tracks`,
    options: {
      body: track,
    },
  });

export const removeTrackForEvent = async (eventId: string, { id: trackId }: any) =>
  await API.del({
    path: `/events/${eventId}/tracks/${trackId}`,
  });

export const addHashtagForEvent = async (eventId: string, hashtag: any) =>
  await API.post({
    path: `/events/${eventId}/hashtags`,
    options: {
      body: hashtag,
    },
  });

export const removeHashtagForEvent = async (eventId: string, { id: hashtagId }: any) =>
  await API.del({
    path: `/events/${eventId}/hashtags/${hashtagId}`,
  });

export const addGenreForEvent = async (eventId: string, genre: any) =>
  await API.post({
    path: `/events/${eventId}/genres`,
    options: {
      body: genre,
    },
  });

export const removeGenreForEvent = async (eventId: string, { id: genreId }: any) =>
  await API.del({
    path: `/events/${eventId}/genres/${genreId}`,
  });

  const cachedSearchEvents = cacheify<(...args: any[]) => Promise<{ items: any[] }>, { items: any[] }>(
    async (q: string, { limit, offset }: PaginationOptions) =>
      await API.get({
        path: '/search',
        options: {
          queryParams: cleanObject({
            q,
            type: 'event',
            provider: 'ticketmaster',
            limit,
            offset,
          }),
        }
      }),
    60000
  );
  
  export const searchEvents = async (q: string, options: PaginationOptions) => await cachedSearchEvents()(q, options);
/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import { cleanObject } from 'src/common/cleanObject';
import { PaginationOptions } from 'src/types/Pagination';
import API from '.';
import { addFollowForItem, removeFollowForItem } from './follows';
import Venue from 'src/types/Venue';

export const addVenueForUser = async (venue: Venue) =>
  await addFollowForItem(get(venue, 'id'));

export const removeVenueForUser = async (venue: Venue) =>
  await removeFollowForItem(get(venue, 'id'));

export const getVenue = async (venueId: string, options?: { cache: boolean }) =>
  await API.get({
   path: `/venues/${venueId}`,
   options: {
      queryParams: cleanObject({
        cache: options && options.cache,
      }),
    },
  });

export const listVenues = async ({
  radius,
  longitude,
  latitude,
  sort,
  offset,
  limit,
}: {
  radius?: number;
  longitude?: number;
  latitude?: number;
  sort?: string;
} & PaginationOptions) =>
  await API.get({
    path: `/venues`,
    options: {
      queryParams: cleanObject({
        radius,
        longitude,
        latitude,
        sort,
        offset,
        limit,
      }),
    },
  });

/* External dependencies */
import React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

/* Internal dependencies */
import MediaObject, { Props as MediaObjectProps } from '../mediaObject/MediaObject';
import { formatNumber } from 'src/common/helpers/numbers';
import Icon, { Icons } from 'src/icon/Icon';

type OwnProps = {
  collection: any;
  right?: React.ReactNode;
  enableLink?: boolean;
} & Partial<MediaObjectProps>;

type Props = OwnProps;

const Collection: React.FunctionComponent<Props> = ({ collection, right, enableLink = false, textSize, ...rest }) => {
  const collectionId = get(collection, 'id', '');
  const verified = get(collection, 'verified', false);
  const events = get(collection, 'events.items', []);
  const eventsTotal = get(collection, 'events.total', events.length);
  const users = get(collection, 'users.items', []);
  const usersTotal = get(collection, 'users.total', users.length);

  return (
    <MediaObject
      text={Boolean(enableLink) ? (
        <Link
          className="d-flex align-items-center"
          to={`/do-lists/${collectionId.replace('collection:', '')}`}
        >
          <h6 className="text-white d-flex flex-wrap m-0 p-0" style={{ fontSize: textSize }}>{get(collection, 'name')}</h6>
          {Boolean(verified) && (
            <Icon
              name={Icons.verified}
              className="bm-Icon--primary ml-1 pb-1"
              size={16}
              solid
            />
          )}
        </Link>
      ) : (
        <div className="d-flex align-items-center">
          <span className="text-white m-0 p-0" style={{ fontSize: textSize }}>{get(collection, 'name')}</span>
          {Boolean(verified) && (
            <Icon
              name={Icons.verified}
              className="bm-Icon--primary ml-1 pb-1"
              size={16}
              solid
            />
          )}
        </div>
      )}
      textSize={textSize}
      subtext={Boolean(eventsTotal) ? `${formatNumber(eventsTotal)} ${eventsTotal === 1 ? 'event' : 'events'}` : undefined}
      imageType="rounded"
      image={get(collection, 'images[0].url')}
      right={right}
      {...rest}
    />
  );
};

export default Collection;
